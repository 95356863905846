import React, { Component } from 'react'
import { withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import UploadFile from './UploadFile'

export const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase()
  return ((ua.indexOf('safari') !== -1) && (ua.indexOf('chrome') === -1))
}
const VIDEO_WIDTH = 640
const VIDEO_HEIGHT = 480

const FIELDS = [
  'format',
  'seriaDr',
  'organWydajacy',
  'numerRejestracyjnyPojazdu',
  'markaPojazdu',
  'typPojazdu',
  'wariantPojazdu',
  'wersjaPojazdu',
  'modelPojazdu',
  'numerIdentyfikacyjnyPojazdu',
  'dataWydaniaDowoduRejestracyjnego',
  'okresWaznosciDowoduRejestracyjnego',
  'pelneNazwiskoLubNazwaPosiadaczaDowoduRejestracyjnego',
  'imiePosiadaczaDowoduRejestracyjnego',
  'nazwiskoPosiadaczaDowoduRejestracyjnego',
  'nazwaPosiadaczaDowoduRejestracyjnego',
  'numerPESELLubREGONPosiadaczaDowoduRejestracyjnego',
  'kodPocztowyPosiadaczaDowoduRejestracyjnego',
  'miejscowoscPosiadaczaDowoduRejestracyjnego',
  'gminaPosiadaczaDowoduRejestracyjnego',
  'ulicaPosiadaczaDowoduRejestracyjnego',
  'nrDomuPosiadaczaDowoduRejestracyjnego',
  'nrMieszkaniaPosiadaczaDowoduRejestracyjnego',
  'pelneNazwiskoLubNazwaWlascicielaPojazdu',
  'imieWlascicielaPojazdu',
  'nazwiskoWlascicielaPojazdu',
  'nazwaWlascicielaPojazdu',
  'numerPESELLubREGONWlascicielaPojazdu',
  'kodPocztowyWlascicielaPojazdu',
  'miejscowoscWlascicielaPojazdu',
  'gminaWlascicielaPojazdu',
  'ulicaWlascicielaPojazdu',
  'nrDomuWlascicielaPojazdu',
  'nrMieszkaniaWlascicielaPojazdu',
  'maksymalnaMasaCalkowitaPojazduKg',
  'dopuszczalnaMasaCalkowitaPojazduKg',
  'dopuszczalnaMasaCalkowitaZespoluPojazdowKg',
  'masaWlasnaPojazduKg',
  'kategoriaPojazdu',
  'numerSwiadectwaHomologacjiTypuPojazdu',
  'liczbaOsi',
  'maksymalnaMasaCalkowitaPrzyczepyZHamulcemKg',
  'maksymalnaMasaCalkowitaPrzyczepyBezHamulcaKg',
  'stosunekMocyDoMasyWlasnejKWkg',
  'pojemnoscSilnikaCm3',
  'maksymalnaMocNettoSilnikaKW',
  'rodzajPaliwa',
  'dataPierwszejRejestracjiPojazdu',
  'liczbaMiejscSiedzacych',
  'liczbaMiejscStojacych',
  'rodzajPojazdu',
  'przeznaczenie',
  'rokProdukcji',
  'dopuszczalnaLadownosc',
  'najwiekszyDopNaciskOsi',
  'nrKartyPojazdu',
  'kodIdentyfikacyjny',
  'rodzajKod',
  'podrodzajKod',
  'przeznaczenieKod',
  'nieznane1',
  'nieznane2'
]
const BOX_SIZE = Math.min(window.innerHeight, window.innerWidth) * 0.5

const buttonStyle = {
  opacity: 0.5,
  left: `${window.innerWidth / 2 - BOX_SIZE * 0.4}px`,
  top: `${window.innerHeight / 2 - BOX_SIZE * 0.1}px`,
  position: 'absolute',
  width: `${BOX_SIZE * 0.8}px`
}

class VideoStream extends Component {
  stream = null
  streamWidth = 0
  streamHeight = 0
  video = null
  canvasContext = null

  constructor (props) {
    super(props)
    this.state = {
      error: false,
      errorMessage: false,
      success: false,
      busy: false,
      testMode: window.location.href.indexOf('test') !== -1
    }
  }

  async componentDidMount () {
    let initSuccess = true
    let message = ''
    try {
      await this.startCamera()
    } catch (e) {
      this.setState({ error: true })
      message = `Browser camera init error: ${e}`
      initSuccess = false
    }

    if (typeof this.props.onInit === 'function') {
      this.props.onInit({ error: initSuccess, message }, this.drawFrame)
    }
  }

  componentWillUnmount () {
    this.stopCamera()
  }

  onTestError = () => {
    this.displayError('Testowy komunikat błędu od pluginu AztecScanner')
  }

  onTestSuccess = () => {
    this.displaySuccess(FIELDS.reduce((o, v, index) => {
      o[FIELDS[index]] = 'test-' + v
      return o
    }, {}))
  }

  stopCamera = () => {
    if (!this.stream) return
    this.stream.getTracks().map(t => t.stop())
    this.stream = null
    this.streamWidth = 0
    this.streamHeight = 0
    this.canvasContext = null
  };

  startCamera = async () => {
    this.stopCamera()

    if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
      this.setState({ error: true })
      return
    }

    const devices = await navigator.mediaDevices.enumerateDevices()
    const cameras = devices.filter(device => device.kind === 'videoinput')
    let videoMode = { aspectRatio: 4 / 3, width: { min: VIDEO_WIDTH }, height: { min: VIDEO_HEIGHT }, facingMode: 'user' }
    if (cameras.length > 1) {
      const cameraIndex = this.props.rearCamera ? 1 : 0
      const cameraEnv = this.props.rearCamera ? 'environment' : 'user'
      videoMode = isSafari() ? { aspectRatio: 4 / 3, width: { min: VIDEO_WIDTH }, height: { min: VIDEO_HEIGHT }, facingMode: { exact: cameraEnv } } : { width: 800, height: 600, deviceId: cameras[cameraIndex].deviceId }
    }

    try {
      this.stream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: videoMode
      })
    } catch (e) {
      this.setState({ error: true })
      return
    }
    if (this.video.srcObject !== undefined) {
      this.video.srcObject = this.stream
    } else if (this.video.mozSrcObject !== undefined) {
      this.video.mozSrcObject = this.stream
    } else if (window.URL.createObjectURL) {
      this.video.src = window.URL.createObjectURL(this.stream)
    } else if (window.webkitURL) {
      this.video.src = window.webkitURL.createObjectURL(this.stream)
    } else {
      this.video.src = this.stream
    }

    this.video.playsInline = true
    this.video.play()

    await this.streamLoadedPromise()
    this.streamWidth = this.video.videoWidth
    this.streamHeight = this.video.videoHeight

    if (!this.canvasContext) {
      const canvas = document.createElement('canvas')
      canvas.width = this.streamWidth
      canvas.height = this.streamHeight
      this.canvasContext = canvas.getContext('2d')
      this.canvas = canvas
    }
  };

  streamLoadedPromise = () => new Promise((resolve, reject) => {
    this.video.addEventListener('loadeddata', resolve, { once: true })
    this.video.addEventListener('error', reject, { once: true })
  });

  captureFrame = () => {
    this.canvasContext.drawImage(this.video, 0, 0, this.streamWidth, this.streamHeight)
    const data = this.canvas.toDataURL('image/png', 1)
    return data
  }

  drawFrame = () => {
    window.requestAnimationFrame(() => {
      if (!this.canvasContext) return
      const { data } = this.captureFrame()
      this.props.onFrame({
        data,
        width: this.streamWidth,
        height: this.streamHeight
      })
    })
  };

  render () {
    const { testMode, success, busy, error, errorMessage } = this.state
    if (error) {
      return (
        <div className="alert alert-danger">
          Nie udało się zainicjalizować kamery. Upewnij się, że urządzenie posiada dostępną kamerę i jej użycie nie jest zablokowane i <a href="#start" onClick={() => { this.startCamera() }}>uruchom ponownie</a>.
          <p className="text-center">
            <a href="#cancel" onClick={this.onCancel} className="btn btn-link">Zamknij okno</a>
          </p>
        </div>
      )
    }

    return (
      <div>
        { errorMessage && <div style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '640px',
          opacity: '.8'
        }} className="alert alert-warning">
          { errorMessage }
        </div> }
        { success && <div style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '640px',
          opacity: '.8'
        }} className="alert alert-success">
          Skanowanie zakończone powodzeniem!
        </div>
        }
        <video muted style={{
          display: 'block',
          width: `${window.innerWidth}px`,
          height: `${window.innerHeight}px`
        }} ref={v => (this.video = v)} />
        <div style={{
          left: `${window.innerWidth / 2 - BOX_SIZE / 2}px`,
          top: `${window.innerHeight / 2 - BOX_SIZE / 2}px`,
          position: 'absolute',
          border: '3px dashed white',
          width: `${BOX_SIZE}px`,
          height: `${BOX_SIZE}px`
        }} />
        { busy
          ? <div style={buttonStyle}> <a className="btn-block btn btn-secondary disabled" href="#takePhoto">Czekaj</a></div>
          : <div style={buttonStyle}>
            <div className="btn-group btn-block">
              <a className="btn btn-primary" onClick={this.onClick} href="#takePhoto">Skanuj kod</a>
              <UploadFile onChange={this.onUploadClick}/>
            </div>
            { testMode && <a className="btn-block btn btn-primary" onClick={this.onTestSuccess} href="#takeTestSuccess">Test OK</a> }
            { testMode && <a className="btn-block btn btn-primary" onClick={this.onTestError} href="#takeTestError">Test Błąd</a> }
            <a className="btn-block btn btn-outline-secondary" onClick={this.onCancel} href="#cancelPhoto">Anuluj</a>
          </div>
        }
      </div>
    )
  }

  onCancel = e => {
    if (window.parent) {
      window.parent.postMessage({ type: 'cancel' }, '*')
    }
    e.preventDefault()
  }

  onUploadClick = async e => {
    e.preventDefault()
    const reader = new window.FileReader()
    const me = this
    reader.addEventListener('load', async function () {
      console.info('r', this)
      me.setState({ busy: true })
      try {
        me.sendImage(this.result.substring('data:image/png;base64,'.length))
      } catch (e) {
        console.error({ e: e })
      }
    }, false)
    reader.readAsDataURL(e.currentTarget.files[0])
  }

  sendImage = async (data) => {
    const { data: { readAndDecode: { success, reason, info } } } = await this.props.client.mutate({
      variables: { data },
      mutation: gql`
        mutation readAndDecode($data: String!) {
          readAndDecode(data: $data) {
            reason {
              code
              message
            }
            success
            info {
              format
              seriaDr
              organWydajacy
              numerRejestracyjnyPojazdu
              markaPojazdu
              typPojazdu
              wariantPojazdu
              wersjaPojazdu
              modelPojazdu
              numerIdentyfikacyjnyPojazdu
              dataWydaniaDowoduRejestracyjnego
              okresWaznosciDowoduRejestracyjnego
              pelneNazwiskoLubNazwaPosiadaczaDowoduRejestracyjnego
              imiePosiadaczaDowoduRejestracyjnego
              nazwiskoPosiadaczaDowoduRejestracyjnego
              nazwaPosiadaczaDowoduRejestracyjnego
              numerPESELLubREGONPosiadaczaDowoduRejestracyjnego
              kodPocztowyPosiadaczaDowoduRejestracyjnego
              miejscowoscPosiadaczaDowoduRejestracyjnego
              gminaPosiadaczaDowoduRejestracyjnego
              ulicaPosiadaczaDowoduRejestracyjnego
              nrDomuPosiadaczaDowoduRejestracyjnego
              nrMieszkaniaPosiadaczaDowoduRejestracyjnego
              pelneNazwiskoLubNazwaWlascicielaPojazdu
              imieWlascicielaPojazdu
              nazwiskoWlascicielaPojazdu
              nazwaWlascicielaPojazdu
              numerPESELLubREGONWlascicielaPojazdu
              kodPocztowyWlascicielaPojazdu
              miejscowoscWlascicielaPojazdu
              gminaWlascicielaPojazdu
              ulicaWlascicielaPojazdu
              nrDomuWlascicielaPojazdu
              nrMieszkaniaWlascicielaPojazdu
              maksymalnaMasaCalkowitaPojazduKg
              dopuszczalnaMasaCalkowitaPojazduKg
              dopuszczalnaMasaCalkowitaZespoluPojazdowKg
              masaWlasnaPojazduKg
              kategoriaPojazdu
              numerSwiadectwaHomologacjiTypuPojazdu
              liczbaOsi
              maksymalnaMasaCalkowitaPrzyczepyZHamulcemKg
              maksymalnaMasaCalkowitaPrzyczepyBezHamulcaKg
              stosunekMocyDoMasyWlasnejKWkg
              pojemnoscSilnikaCm3
              maksymalnaMocNettoSilnikaKW
              rodzajPaliwa
              dataPierwszejRejestracjiPojazdu
              liczbaMiejscSiedzacych
              liczbaMiejscStojacych
              rodzajPojazdu
              przeznaczenie
              rokProdukcji
              dopuszczalnaLadownosc
              najwiekszyDopNaciskOsi
              nrKartyPojazdu
              kodIdentyfikacyjny
              rodzajKod
              podrodzajKod
              przeznaczenieKod
              nieznane1
              nieznane2
            }
          }
        }
      `
    })
    this.setState({ busy: false })
    if (success) {
      console.info(info)
      this.displaySuccess(info)
    } else {
      this.displayError(reason.message)
    }
  }

  onClick = async e => {
    e.preventDefault()
    this.setState({ busy: true })
    try {
      await this.sendImage(this.captureFrame().substring('data:image/png;base64,'.length))
    } catch (e) {
      console.error({ e: e })
    }
  }

  displayError = errorMessage => {
    if (window.parent) {
      window.parent.postMessage({ type: 'error', errorMessage }, '*')
    }
    this.setState({ errorMessage })
    setTimeout(() => {
      this.setState({ errorMessage: false })
    }, 3000)
  }

  displaySuccess = info => {
    if (window.parent) {
      window.parent.postMessage({ type: 'success', info }, '*')
    }
    this.setState({ success: true })
    setTimeout(() => {
      this.setState({ success: false })
    }, 3000)
  }
}

export default withApollo(VideoStream)
