import React from 'react'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'

const Footer = () => (
  <Query query={gql`{ build }`}>
    {({ loading, error, data: { build } }) => {
      if (loading) return 'Trwa uruchamianie ...'
      if (error) return `Błąd! ${error.message}`
      return <footer style={{
        position: 'absolute',
        color: '#fff',
        fontSize: '80%',
        left: '5px',
        top: '5px'
      }}>wersja 17.{build}</footer>
    }}
  </Query>
)

export default Footer
