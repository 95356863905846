import React, { Component } from 'react'
import Footer from './components/Footer'
import VideoStream from './components/VideoStream'

class App extends Component {
  state = {
    cameraOn: true
  }
  render () {
    return (
      <div>
        { this.state.cameraOn && <VideoStream rearCamera onFrame={() => {}} onInit={() => {}}/> }
        { !this.state.cameraOn && <a onClick={e => {
          e.preventDefault()
          this.setState({ cameraOn: true })
        }} href="#cameraOn" className="btn-primary btn">Uruchom</a>}
        <Footer />
      </div>
    )
  }
}

export default App
