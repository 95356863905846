import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImages, faSpinner } from '@fortawesome/pro-regular-svg-icons'
const UploadFile = ({ onChange, busy = false }) => (
  <div className="file btn btn-light d-block" style={{
    position: 'relative',
    overflow: 'hidden'
  }}> <FontAwesomeIcon size="lg" icon={busy ? faSpinner : faImages} spin={busy} />
    <input accept="image/png" onChange={onChange} type="file" className="file" style={{
      position: 'absolute',
      fontSize: '20px',
      opacity: '0',
      right: '10px',
      top: '0'
    }} />
  </div>
)

export default UploadFile
