import React from 'react'
import ReactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.css'
import App from './App'
import ApolloClient from 'apollo-boost'
import { ApolloProvider } from 'react-apollo'

ReactDOM.render(
  <ApolloProvider client={new ApolloClient({
    uri: 'https://f9zccithk5.execute-api.eu-central-1.amazonaws.com/production/graphql'
  })}>
    <App />
  </ApolloProvider>, document.getElementById('root')
)
